import "@/i18n";
import companyInfoImage from "@/img/company-name.png";
import logoPath from "@/img/logo.svg";
import { UploadsProvider } from "@cospex/client/converter/context/UploadsProvider";
import Providers from "@cospex/client/provider";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import { Routes } from "@generouted/react-router";
import { createRoot } from "react-dom/client";

import theme from "./Theme";

const container = document.getElementById("app")!;

createRoot(container).render(
  <Providers
    theme={theme}
    customization={{
      logo: {
        src: logoPath,
      },
      companyInfoImage,
      languages: [
        { value: "en", label: "EN 🇬🇧" },
        { value: "fr", label: "FR 🇫🇷" },
        { value: "it", label: "IT 🇮🇹" },
      ],
    }}
  >
    <UploadsProvider>
      <Routes />
    </UploadsProvider>
  </Providers>
);
