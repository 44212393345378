import { OPERATION } from "@cospex/client/converter/constants";
import { getExtensionColor } from "@cospex/client/converter/helpers";
import useUploads from "@cospex/client/converter/hooks/useUploads";
import { ArrowForward, Check } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import StopIcon from "@mui/icons-material/Stop";
import {
  Alert,
  Box,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";
import { t } from "i18next";

interface FileTypeBoxInterface {
  type: string;
}

const FileTypeBox = ({ type }: FileTypeBoxInterface) => (
  <Box
    sx={{
      width: 40,
      height: 40,
      borderRadius: 40,
      mr: 1,
      backgroundColor: getExtensionColor(type),
      justifyContent: "center",
      alignItems: "center",
      fontSize: 11,
      fontWeight: 600,
      color: "white",
      display: {
        xs: "none",
        sm: "flex",
      },
    }}
  >
    {type.toUpperCase()}
  </Box>
);

const Upload = ({ upload }: { upload: Upload }) => {
  const { removeUpload, stopUpload, updateConversion } = useUploads();

  let childEl = null;
  if (upload.conversionTargets.length === 0) {
    childEl = (
      <Alert sx={{ flex: 1 }} severity="error">
        {t("uploader-invalid-file-type")}
      </Alert>
    );
  } else {
    childEl = (
      <>
        <Box
          sx={{
            flex: "1 1 auto",
            minWidth: 0,
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              fontWeight: 500,
              mb: 1,
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Box
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {upload.file.name}
            </Box>
            <Box>
              {upload.progress === 100 && (
                <Check sx={{ fontSize: 18, color: "success.main" }} />
              )}
            </Box>
          </Box>
          <LinearProgress
            variant="determinate"
            value={upload.progress}
            color={
              upload.progress === 100
                ? "success"
                : upload.progress < 0
                ? "error"
                : "primary"
            }
            sx={{
              flex: 5,
              height: 7,
              borderRadius: 2,
            }}
          />
          {upload.progress === -1 && (
            <Typography
              variant="body2"
              sx={{
                flex: 1,
                mt: 1,
                color: "error.main",
              }}
            >
              {t("uploader-error")}
            </Typography>
          )}
          {upload.progress === -2 && (
            <Typography
              variant="body2"
              sx={{
                flex: 1,
                mt: 1,
                color: "error.main",
              }}
            >
              {t("upload-cancelled")}
            </Typography>
          )}
        </Box>
        {upload.operationType !== OPERATION.COMPRESSION && (
          <>
            <Box
              sx={{
                display: "flex",
                p: 1,
                borderRadius: 8,
                backgroundColor: "white",
                aspectRatio: 1,
              }}
            >
              <ArrowForward />
            </Box>
            <Box>
              <FormControl size="small">
                <InputLabel id="type">{t("uploader-type-label")}</InputLabel>
                <Select
                  label="File type"
                  variant="outlined"
                  value={upload.convertTo || ""}
                  renderValue={(value) => (
                    <Stack direction="row" alignItems="center" py={1}>
                      <FileTypeBox type={value} />
                      {value.toUpperCase()}
                    </Stack>
                  )}
                  onChange={(e) => {
                    updateConversion(upload.id, e.target.value as string);
                  }}
                  MenuProps={{
                    sx: {
                      maxHeight: 290,
                    },
                  }}
                  sx={{
                    minWidth: 85,
                  }}
                >
                  {upload.conversionTargets.map((type) => (
                    <MenuItem key={type} value={type}>
                      <FileTypeBox type={type} />
                      {type.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </>
        )}
      </>
    );
  }

  return (
    <Box
      key={upload.id}
      sx={{
        width: "100%",
        py: 1,
        borderRadius: 2,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        gap={1}
        sx={{
          width: "100%",
        }}
      >
        {childEl}
        <Box sx={{ flex: "0 1 auto" }}>
          {upload.progress < 100 && upload.progress >= 0 ? (
            <IconButton size="small" onClick={() => stopUpload(upload.id)}>
              <StopIcon />
            </IconButton>
          ) : (
            <IconButton size="small" onClick={() => removeUpload(upload.id)}>
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      </Stack>
    </Box>
  );
};

const UploadsList = ({ sx }: { sx?: SxProps }) => {
  const { uploads } = useUploads();
  return (
    uploads.length > 0 && (
      <Stack direction="column" gap={1} sx={sx}>
        {uploads.map((upload) => (
          <Upload key={upload.id} upload={upload} />
        ))}
      </Stack>
    )
  );
};

export default UploadsList;
