import HomepageUploaderContainer from "@cospex/client/converter/components/HomepageUploaderContainer";
import HomepageUploaderInput from "@cospex/client/converter/components/HomepageUploaderInput";
import UploaderLoadingOverlay from "@cospex/client/converter/components/UploaderLoadingOverlay";
import UploaderSubmitButton from "@cospex/client/converter/components/UploaderSubmitButton";
import UploadsList from "@cospex/client/converter/components/UploadsList";
import { getOperationFromQuery } from "@cospex/client/converter/helpers";
import useHomepageUploaderState from "@cospex/client/converter/hooks/useHomepageUploaderState";
import useUploads from "@cospex/client/converter/hooks/useUploads";
import TextInput from "@cospex/client/forms/TextInput";
import useAuth from "@cospex/client/hooks/useAuth";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { Alert, Box, SxProps, Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const HomepageUploaderCompact = ({ sx }: { sx?: SxProps }) => {
  const { uploads } = useUploads();
  const { session } = useAuth();
  const { compressionFormat } = getOperationFromQuery();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { control, onHandleSubmit, formState, showProgress, error, hasUpload } =
    useHomepageUploaderState();

  const isUploadValid = Boolean(
    uploads[0]?.progress === 100 && uploads[0]?.convertTo
  );

  const getTitle = () => {
    if (hasUpload) {
      if (!isUploadValid) {
        if (compressionFormat !== null) {
          return t("uploader-title-compress-choose");
        } else {
          return t("uploader-title-convert");
        }
      } else {
        if (compressionFormat !== null) {
          return t("uploader-title-compress-email");
        } else {
          return t("uploader-title-email");
        }
      }
    } else {
      if (compressionFormat !== null) {
        return t("uploader-title-compress");
      } else {
        return t("uploader-title");
      }
    }
  };

  useEffect(() => {
    if (hasUpload && !session) {
      const emailInput = document.querySelector(
        "[name=email]"
      ) as HTMLInputElement;
      emailInput?.focus();
    }
  }, [hasUpload, session]);

  const uploadSubmittButtonSx = {
    fontSize: {
      lg: "1.1rem",
    },
    py: {
      xs: 1,
      lg: 2,
    },
  };
  return (
    <>
      <HomepageUploaderContainer sx={sx}>
        <Typography variant="h4">{getTitle()}</Typography>
        <Box
          component="form"
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
          onSubmit={onHandleSubmit}
        >
          {!hasUpload && (
            <HomepageUploaderInput>
              <Box sx={{ p: 4, width: "100%" }}>
                <UploaderSubmitButton
                  sx={uploadSubmittButtonSx}
                  hasUpload={false}
                  compressionFormat={compressionFormat}
                />
              </Box>
            </HomepageUploaderInput>
          )}
          <UploadsList />

          {hasUpload && !session && (
            <TextInput
              control={control}
              fullWidth
              name="email"
              label="Email"
              formState={formState}
              sx={{
                mb: 2,
              }}
            />
          )}
          {hasUpload && (
            <UploaderSubmitButton
              sx={uploadSubmittButtonSx}
              hasUpload={true}
              compressionFormat={compressionFormat}
            />
          )}
          {error && (
            <Alert sx={{ mt: 2 }} severity="error">
              {t(error)}
            </Alert>
          )}
        </Box>
        <UploaderLoadingOverlay
          showProgress={showProgress}
          onComplete={() => {
            navigate("/payment");
          }}
        />
      </HomepageUploaderContainer>
    </>
  );
};

export default HomepageUploaderCompact;
