import HomepageUploaderCompact from "@cospex/client/converter/components/HomepageUploaderCompact";
import SectionHeroTitle from "@cospex/client/converter/components/SectionHeroTitle";
import Rating from "@cospex/client/converter/img/rating.svg";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { CheckCircle } from "@mui/icons-material";
import {
  Box,
  Grid,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";

function BulletPointBox() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        pt: {
          xs: 4,
          md: 0,
        },
        px: {
          xs: 2,
          md: 0,
        },
        pb: {
          xs: 2,
          md: 0,
        },
        "> .MuiBox-root": {
          display: "flex",
          gap: 2,
          textAlign: "left",
        },
        " .MuiTypography-root": {
          fontSize: 14,
        },
        ".MuiSvgIcon-root": {
          color: "primary.main",
        },
      }}
    >
      <Box>
        <CheckCircle />
        <Typography>{t("homepage-chip-a")}</Typography>
      </Box>
      <Box>
        <CheckCircle />
        <Typography>{t("homepage-chip-b")}</Typography>
      </Box>
      <Box>
        <CheckCircle />
        <Typography>{t("homepage-chip-c")}</Typography>
      </Box>
    </Box>
  );
}

export default function SectionHeroInnerDefault() {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  return (
    <Grid
      container
      spacing={{
        xs: 2,
        md: 6,
      }}
      direction="row"
    >
      <Grid
        item
        xs={12}
        md={5}
        sx={{
          display: "flex",
          gap: {
            xs: 2,
            md: 4,
          },
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <SectionHeroTitle />
          <Typography
            variant="subtitle1"
            sx={{
              mb: {
                md: 4,
              },
              fontSize: 14,
            }}
          >
            {t("homepage-subtitle")}
          </Typography>
          {isDesktop ? <BulletPointBox /> : null}
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            {t("satisfied-customers")}
          </Typography>
          <Stack direction="row" gap={1}>
            <Box
              component="img"
              src={Rating}
              alt="Hero Image"
              sx={{
                maxWidth: 160,
                height: "auto",
              }}
            />
            <Typography variant="subtitle1" fontSize={14} fontStyle="italic">
              {t("reviews-rating-text")}
            </Typography>
          </Stack>
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        md={7}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <HomepageUploaderCompact
          sx={{
            flex: 1,
            minHeight: 520,
            p: 4,
          }}
        />
        {!isDesktop ? <BulletPointBox /> : null}
      </Grid>
    </Grid>
  );
}
