import { Box, Card, SxProps, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";

const HomepageUploaderContainer = ({
  children,
  sx,
}: {
  children: React.ReactNode;
  sx?: SxProps;
}) => {
  const theme = useTheme();
  const [offsetPosition, setOffsetPosition] = useState({ x: 0, y: 0 });
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (!ref.current) return;
      const rect = ref.current.getBoundingClientRect();
      const offsetX = e.clientX - rect.left;
      const offsetY = e.clientY - rect.top;
      setOffsetPosition({ x: offsetX, y: offsetY });
    };
    document.addEventListener("mousemove", handleMouseMove);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <Card
      sx={{
        width: "100%",
        backgroundColor: "white",
        p: { xs: 2, sm: 5, lg: 8 },
        zIndex: 1,
        borderRadius: 4,
        position: "relative",
        boxShadow: `0 0 20px 0 ${theme.palette.primary.main}2b`,
        gap: 1,
        ...sx,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          transform: "translate(-50%, -50%)",
          width: 640,
          height: 640,
          zIndex: 20,
          background: `radial-gradient(circle, rgba(255,255,255,1) 15%, rgba(255,255,255,0) 80%)`,
        }}
        style={{ top: offsetPosition.y, left: offsetPosition.x }}
      />
      <Box
        ref={ref}
        sx={{
          zIndex: 10,
          position: "absolute",
          right: 0,
          bottom: 0,
          top: 0,
          left: 0,
          borderRadius: 4,
          overflow: "hidden",
          borderColor: "primary.main",
          borderWidth: 2,
          borderStyle: "dashed",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            right: 0,
            bottom: 0,
            top: 0,
            left: 0,
            background: theme.palette.primary.main,
            borderRadius: `15px`,
          }}
        />
        <Box
          sx={{
            position: "absolute",
            right: 0,
            bottom: 0,
            top: 0,
            left: 0,
            background: "rgba(255,255,255,0.96)",
            borderRadius: `15px`,
          }}
        />
      </Box>
      <Box
        sx={{
          zIndex: 20,
          flex: 1,
          width: "100%",
          height: "100%",
          position: "relative",
          display: "flex",
          gap: 1,
          flexDirection: "column",
        }}
      >
        {children}
      </Box>
    </Card>
  );
};

export default HomepageUploaderContainer;
